import Vue from 'vue';
import Vuex from 'vuex';
import Result from '@/store/modules/Result';
import GeneralControl from '@/store/modules/GeneralControl';
import Game from './modules/Game';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Result,
    Game,
    GeneralControl,
  },
});
