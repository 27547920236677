
import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import ResultModule from '@/store/modules/Result';

@Component
export default class Result extends Vue {
  ResultInstance = getModule(ResultModule, this.$store);

  queryData = {
    params: {
      roundId: this.$route.query.roundId || '',
    },
  };

  resultList: string[] = [];

  currentResult = 0;

  currentLink = '';

  async created() {
    const { url } = await this.ResultInstance.fetchResult(this.queryData);
    this.resultList = [...url];
    this.currentLink = this.resultList[this.currentResult];
  }

  onChangeIframe(state: number) {
    this.currentResult += state;
    this.currentLink = this.resultList[this.currentResult];
  }
}
