import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import Vue from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';
import VueMq from 'vue-mq';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import interceptorsSetup from '@/middleware/axios-interceptors';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';

interceptorsSetup();

Vue.use(BootstrapVue);

Vue.use(IconsPlugin);

Vue.use(VueAxios, axios);

Vue.config.productionTip = false;

Vue.use(BootstrapVue);

Vue.use(IconsPlugin);

Vue.use(VueAxios, axios);

Vue.use(VueMq, {
  breakpoints: { // default breakpoints - customize this
    mb: 460,
    sm: 769,
    md: 1250,
    lg: Infinity,
  },
});

Vue.use(VueSweetalert2);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
