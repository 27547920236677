import {
  Module,
  VuexModule,
  Action,
} from 'vuex-module-decorators';

import Vue from 'vue';
import {
  BASE_URL,
  LAUNCH_GAME,
  LAUNCH_GAME_DEMO,
  M_POST,
} from '@/utils/api';

@Module({ name: 'game' })
export default class Game extends VuexModule {
  @Action
  LaunchGame(params: any) {
    return Vue.axios({
      url: BASE_URL + LAUNCH_GAME,
      data: params.data,
      method: M_POST,
      headers: {
        Authorization: `Bearer ${params.token}`,
      },
    })
      .then(({ data }) => {
        const { code, message } = data.status;
        if (code === 0) {
          return data;
        }
        throw message;
      })
      .catch((err) => err);
  }

  @Action
  LaunchGameDemo(params: any) {
    return Vue.axios({
      url: BASE_URL + LAUNCH_GAME_DEMO,
      data: params,
      method: M_POST,
    })
      .then(({ data }) => {
        const { code, message } = data.status;
        if (code === 0) {
          return data.data.url;
        }
        throw message;
      })
      .catch((err) => err);
  }
}
