import {
  VuexModule, Module, Action,
} from 'vuex-module-decorators';
import Vue from 'vue';
import {
  GET_RESULT_SPADEGAMEING,
  M_POST,
  BASE_URL,
} from '@/utils/api';

@Module({ name: 'Result' })
export default class Result extends VuexModule {
  @Action
  async fetchResult(body: any) {
    return Vue.axios({
      url: BASE_URL + GET_RESULT_SPADEGAMEING,
      data: body.params,
      method: M_POST,
    })
      .then(({ data }) => {
        const { status } = data;
        if (status.code) throw status;
        else {
          return data.data;
        }
      })
      .catch((err: string) => err);
  }
}
