
import { Component, Vue } from 'vue-property-decorator';
import Result from '@/views/Result.vue';

@Component({
  components: {
    Result,
  },
})
export default class ResultLayout extends Vue {}
