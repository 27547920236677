
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class EvennPopup extends Vue {
  @Prop() readonly isDuplicate!: boolean;

  @Prop() readonly isConnectError!: boolean;

  @Prop() readonly exitFromDuplicate!: Function;

  @Prop() readonly fontStyle!: Function;
}
