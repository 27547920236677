/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
import axios from 'axios';
import NodeRSA from 'node-rsa';
import { PUBLIC_ASG_KEY, ASG_SECRET } from '@/utils/api';

const RSA = new NodeRSA(PUBLIC_ASG_KEY);
localStorage.setItem('x-authorize', RSA.encrypt(ASG_SECRET, 'base64'));

export default function setup() {
  axios.interceptors.request.use(async (config) => {
    config.headers['x-authorize'] = localStorage.getItem('x-authorize');
    return config;
  }, (err) => Promise.reject(err));
}
