export const ENV = 'prod';
export const GENERIC_URL = {
  local: {
    api: 'https://api-staging.ambprovider-hub.com/',
    socket: 'https://skt-staging.ambprovider-hub.com/',
  },
  dev: {
    api: 'https://api-staging.ambprovider-hub.com/',
    socket: 'https://skt-staging.ambprovider-hub.com/',
  },
  uat: {
    api: 'https://api-uat.ambprovider-hub.com/',
    socket: 'https://skt-uat.ambprovider-hub.com/',
  },
  prod: {
    api: 'https://api-prod.ambprovider-hub.com/',
    socket: 'https://skt-prod.ambprovider-hub.com/',
  },
};

export const BASE_URL = GENERIC_URL[ENV].api;
export const SOCKET_URL = GENERIC_URL[ENV].socket;

export const M_GET = 'GET';
export const M_POST = 'POST';
export const M_PUT = 'PUT';
export const M_DELETE = 'DELETE';

export const SECRET_KEY = 'ambsupergame-visa-to-access';
export const ASG_SECRET = 'asg-secret-to-verify';
export const PUBLIC_ASG_KEY = '-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCZdY175sVrKhdkJu7Gv99zwv9b3RFxXbrzNfdiX6g01RgjfAvqt4aFSTfeqEe9/tBlANRauOLEpjmrj/mPX6jEhXe5qoIdIJ2Rv4ZH8yqNp1VkzQRvR6tYLTRvAt5nPebIUrBUn217kPYV+f5jsZm4ryT73cyQ0nc8btSY7l8vDQIDAQAB-----END PUBLIC KEY-----';

export const LAUNCH_GAME = 'game/launch';
export const LAUNCH_GAME_DEMO = 'game/launchdemo';

export const GET_RESULT_SPADEGAMEING = 'spadegaming/history';
