
import { Component, Prop, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import GeneralControl from '@/store/modules/GeneralControl';

@Component
export default class GamePlay extends Vue {
  GeneralControlModule = getModule(GeneralControl, this.$store);

  @Prop() private gameLink!: string;

  @Prop() readonly backLink!: string;

  @Prop() readonly onLeaveGame!: Function;

  @Prop() readonly token!: string;

  @Prop() readonly provider!: string;

  count = 0;

  change = false;

  load() {
    this.count += 1;
    if (
      this.count > (this.provider === 'PGSOFT' ? 2 : 1)
      && !['MICROGAMING', 'ADVANTPLAY'].includes(this.provider)
      && !this.GeneralControlModule.changeGameState
    ) {
      window.location.replace(this.backLink);
      this.onLeaveGame();
      this.change = true;
    } else {
      this.GeneralControlModule.updateChangeGameState(false);
    }
  }

  mounted() {
    window.addEventListener('message', this.handlePostMessage, false);
  }

  handlePostMessage(event: any) {
    if (event.data.method === 'launchlobby') {
      window.location.replace(this.backLink);
      this.onLeaveGame();
    }
  }
}
