import {
  VuexModule, Module, MutationAction, Action, Mutation,
} from 'vuex-module-decorators';

@Module({ name: 'GeneralControl' })
export default class GeneralControl extends VuexModule {
  changeGameState = false;

  @MutationAction
  async updateChangeGameState(changeGameState: boolean) {
    return {
      changeGameState,
    };
  }
}
