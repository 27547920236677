
import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import jwtDecode from 'jwt-decode';
import screenfull from 'screenfull';
import { io } from 'socket.io-client';
import GameModule from '@/store/modules/Game';
import GamePlay from '@/components/GamePlay.vue';
import TouchFullScreen from '@/components/TouchFullScreen.vue';
import { SOCKET_URL } from '@/utils/api';
import DuplicatePopUp from '@/components/DuplicationLog.vue';
import GeneralControl from '@/store/modules/GeneralControl';

@Component({
  components: {
    GamePlay,
    TouchFullScreen,
    DuplicatePopUp,
  },
})
export default class Home extends Vue {
  GameInstance = getModule(GameModule, this.$store);

  GeneralControlModule = getModule(GeneralControl, this.$store);

  componentKeys = 0;

  openSide = false;

  gameLink = '';

  userDetail: object = {};

  token = '';

  mode = '';

  provider = '';

  gameType = '';

  gameCode = '';

  isFullScreen = false;

  isFishingDs = false;

  isRotate = false;

  prefix = '';

  duplicateMessage = '';

  isDuplicate = false;

  isConnectError = false;

  ioConnect: any = {};

  language = 'en';

  backLink: any = {};

  async created() {
    const { userKey, link } = this.$route.query;
    const key = JSON.stringify(userKey);
    this.backLink = link;
    if (userKey) {
      const {
        gameCode, provider, token, gameType, gameName, language: _language,
      }: any = jwtDecode(key);
      this.token = token;
      this.prefix = `${provider}_${gameCode}`;
      this.provider = provider.toUpperCase();
      this.gameType = gameType.toUpperCase();
      const titleName = document.getElementsByTagName('title');
      titleName[0].text = gameName;
      this.handleFishing(this.gameType, this.provider);
      window.addEventListener('message', async (e) => { await this.onWebViewMessage(e); });
      const code = {
        data: {
          gameCode,
          provider,
          backLink: link,
          language: _language || 'en',
        },
        token,
      };
      const res = await this.GameInstance.LaunchGame(code);
      if (res.status.code === 0) {
        const linkGame = res.data.url;
        if (linkGame.search('https://') < 0 && this.provider === 'PGSOFT') {
          // let customLink = linkGame.split('</style>')[1];
          // customLink = customLink.replace('<div id="debug"><div class="t">DEBUG</div>', '');
          // customLink = customLink.replace('z[0].innerHTML=y[a]', 'z[0].innerHTML=y[b]');
          // customLink = customLink.replace('<div class="content">', '<div class="">');
          this.gameLink = linkGame;
        } else {
          this.provider = this.provider === 'PGSOFT' ? 'PGC' : this.provider;
          this.gameLink = linkGame;
        }
        this.ioConnect = await io(SOCKET_URL, {
          auth: { token: `Bearer ${token}` },
          transports: ['websocket'],
        });
        this.ioConnect.on('connect', () => {
          console.log('connected');
          this.ioConnect.on('Authenticated', async () => {
            this.onEnterGame(this.ioConnect);
            this.socketOn(this.ioConnect);
          });
        });
        this.ioConnect.on('connect_error', (error: any) => {
          if (error.data.type === 'UnauthorizedError') {
            console.log('User token has expired');
          }
        });
        this.ioConnect.on('invalidToken', (error: any) => {
          if (error.status.code === 10020) {
            this.gotDuplicate(1002);
          }
        });
      } else {
        this.isConnectError = true;
        this.exitFromDuplicate();
      }
    }
  }

  mounted() {
    if (screenfull.isEnabled) {
      if (/Android|webOS|IEMobile|Opera Mini/i.test(navigator.userAgent)
          && ['FISHING'].includes(this.gameType)
            && ['DRG'].includes(this.provider)) {
        if (screenfull.isFullscreen) {
          this.isFishingDs = false;
        } else {
          this.isFishingDs = true;
        }
      }
    }
  }

  handleFishing(gameType: string, provider: string) {
    if (['FISHING'].includes(gameType) && ['SPADEGAMING', 'SLOTXO', 'DRG'].includes(provider)) {
      if (/Android|webOS|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        this.isFishingDs = true;
      }
    }
  }

  handleDs() {
    document.documentElement.requestFullscreen();
    this.isFishingDs = false;
  }

  onEnterGame(socket: any) {
    socket.emit('enterRoom', { prefix: this.prefix }, (res: any) => {
      console.log('enterRoom', res);
    });
  }

  async onWebViewMessage(event: any) {
    const data = JSON.parse(event.data);
    const condition = !!(event && data);
    if (condition) {
      const isLeaveGame = ['changeLanguage'].includes(data.message);
      this.GeneralControlModule.updateChangeGameState(isLeaveGame);
    }
  }

  gotDuplicate(code: any) {
    if (code === 1002) {
      this.isDuplicate = true;
    }
  }

  socketOn(socket: any) {
    socket.on('disconnectTrigger', (res: any) => {
      this.duplicateMessage = res.status.message;
      if (res.status.code === 1002) {
        this.isDuplicate = true;
      }
    });
    socket.on('disconnect', (res: any) => {
      console.log('disconnect', res);
    });
  }

  onLeaveGame() {
    this.ioConnect.emit('leaveRoom', { prefix: this.prefix });
  }

  async exitFromDuplicate(): Promise<void> {
    delete Vue.axios.defaults.headers.common.Authorization;
    window.close();
  }

  destroyed() {
    this.onLeaveGame();
  }

  fontStyle() {
    if (this.language === 'th') {
      return 'thai-font';
      // eslint-disable-next-line no-else-return
    } else {
      return 'eng-font';
    }
  }
}
